import React from "react";
import cx from "classnames";
import getStyle from "utils/getStyle";
import styles from "./style.css";

const DashboardBlock = ({
  title,
  action,
  content,
  contentLooks,
  disclaimer,
}) => {
  const customContentStyles = getStyle({ looks: contentLooks, styles });
  return (
    <div className={styles.block}>
      <div className={styles.titleLine}>
        <h3 className="accent-text-color">{title}</h3>
        {action && (
          <div
            className={cx(styles.action, "accent-text-color")}
            onClick={action.onClick}
          >
            <span>{action.label}</span>
          </div>
        )}
      </div>
      {disclaimer && <div>{disclaimer}</div>}
      <div className={customContentStyles}>{content}</div>
    </div>
  );
};

DashboardBlock.defaultProps = {
  contentLooks: [""],
};

export default DashboardBlock;
